<template>
  <div class="cookie-consent" v-if="!accepted">
    <p>
      Este site usa cookies para melhorar a experiência do usuário. Ao usar nosso
      site, você concorda com o uso de cookies, conforme descrito em nossa
      <a href="/privacy.html" target="_blank">Política de Privacidade</a>.
    </p>
    <button @click="acceptCookies">Aceitar</button>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      accepted: localStorage.getItem("cookie-consent-accepted") === "true",
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookie-consent-accepted", "true");
      this.accepted = true;
    },
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.cookie-consent p {
  margin: 0;
}

.cookie-consent a {
  color: #fff;
}

.cookie-consent button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 3px;
}
</style>
