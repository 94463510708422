<template>
  <div class="services-container">
    <div class="service-box lgpd">
      <img
        src="../../assets/lgpd.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">LGPD</h3>
      <p class="service-description">
        A Sistnet oferece consultoria em LGPD para empresas de todos os tamanhos, garantindo conformidade com a lei e proteção dos dados de seus clientes.
      </p>
    </div>
    <div class="service-box data-analysis">
      <img
        src="../../assets/data-analise.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">Análise de Dados</h3>
      <p class="service-description">
        Com nossa expertise em análise de dados, ajudamos empresas a obter insights valiosos a partir de suas informações, aprimorando suas tomadas de decisão e aumentando seus resultados.
      </p>
    </div>
    <div class="service-box data-engineering">
      <img
        src="../../assets/engenharia.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">Engenharia de Dados</h3>
      <p class="service-description">
        Nossa equipe de engenharia de dados desenvolve soluções personalizadas para coleta, armazenamento e processamento de dados, garantindo escalabilidade, segurança e eficiência em sua gestão.
      </p>
    </div>
    <div class="service-box solutions-development">
      <img
        src="../../assets/desenvolvimento.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">Desenvolvimento de Soluções</h3>
      <p class="service-description">
        Com ampla experiência em desenvolvimento de software, a Sistnet cria soluções personalizadas para as necessidades de cada cliente, com qualidade, agilidade e eficiência.
      </p>
    </div>
    <div class="service-box apis">
      <img
        src="../../assets/api.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">APIs</h3>
      <p class="service-description">
        Oferecemos serviços de desenvolvimento, implantação e gestão de APIs para integração de sistemas e troca de informações, garantindo agilidade, eficiência e segurança em suas operações.
      </p>
    </div>
    <div class="service-box hosting">
      <img
        src="../../assets/hosting.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">Hospedagem de Sites</h3>
      <p class="service-description">
        Oferecemos hospedagem de sites com alta performance, segurança e disponibilidade, garantindo a presença digital de nossos clientes 24 horas por dia, 7 dias por semana.
      </p>
    </div>
    <div class="service-box email-pro">
      <img
        src="../../assets/email.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">E-mail Profissional</h3>
      <p class="service-description">
        Oferecemos serviços de e-mail corporativo personalizado com a marca da sua empresa, garantindo uma comunicação profissional e segura com seus clientes e parceiros.
      </p>
    </div>
    <div class="service-box ideas">
      <img
        src="../../assets/new-products-development.jpg"
        alt="Ideias de desenvolvimento de novos produtos"
        width="250px"
      />
      <h3 class="service-title">Desenvolvimento de Novos Produtos</h3>
      <p class="service-description">
        Se você tem uma ideia inovadora, nossa equipe da Sistnet está pronta para ajudar a transformá-la em um produto real. Trabalhamos em parceria com nossos clientes para desenvolver soluções personalizadas que atendam às suas necessidades e garantam o sucesso no mercado.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicosComponent",
};
</script>

<style scoped>
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.service-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.service-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-description {
  font-size: 1.2rem;
  line-height: 1.5;
}

.lgpd { 
  background-color: #e74c3c;
  color: #fff;
}

.data-analysis {
  background-color: #2980b9;
  color: #fff;
}

.data-engineering {
  background-color: #2ecc71;
  color: #fff;
}

.solutions-development {
  background-color: #f1c40f;
  color: #fff;
}

.apis {
  background-color: #9b59b6;
  color: #fff;
}

.hosting {
  background-color: #e67e22;
  color: #fff;
}

.email-pro {
  background-color: #3498db;
  color: #fff;
}

.ideas {
  background-color: #1abc9c;
  color: #fff;
}
</style>
