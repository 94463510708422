<template>
  <div class="pagina-principal">
    <HeaderComponent @scrollToSection="scrollToSection" />
    <div class="imgdev-section">
      <img src="../assets/developer.jpeg" alt="Imagem de destaque" />
    </div>
    <div class="menu-section">
      <section id="inicio">
        <InicioComponent />
      </section>
      <section id="servicos">
        <ServicosComponent />
      </section>
      <section id="contato">
        <ContatoComponent />
      </section>
    </div>
    <FooterComponent />
  </div>
  <a href="https://api.whatsapp.com/send?phone=5582981409829" target="_blank" rel="noopener noreferrer" class="whatsapp-link">
    <img src="../assets/whatsapp.png" alt="WhatsApp" />
  </a>
</template>

<script>
import HeaderComponent from "./common/Header.vue";
import FooterComponent from "./common/Footer.vue";
import InicioComponent from "./sections/Inicio.vue";
import ServicosComponent from "./sections/Servicos.vue";
import ContatoComponent from "./sections/Contato.vue";

export default {
  name: "HomeComponent",
  components: {
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    ServicosComponent,
    ContatoComponent,
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
.pagina-principal {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 40px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.imgdev-section {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
}

.imgdev-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img {
  transition: opacity 0.3s ease-in-out;
}

.whatsapp-link {
  position: fixed;
  bottom: 80px; /* Mudei de 30px para 80px */
  right: 20px;
  z-index: 100;
}

.whatsapp-link img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

.whatsapp-link img:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .imgdev-section {
    height: 300px;
  }
}
</style>
