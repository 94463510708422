<template>
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-column">
          <h3>Endereço</h3>
          <p>Rua Bacharel Floriano Ivo, 174 - Sala 314</p>
          <p>Centro Empresarial João de Melo ABS</p>
          <p> Farol - Maceió - Alagoas - Brasil</p>
          <p>Telefone: (82) 98140-9829</p>
          <p>Email: contato@sistnet.com.br</p>
        </div>
        <div class="footer-column menu-column">
          <h3>Menu</h3>
          <ul class="footer-links">
            <li><a href="#">Home</a></li>
            <li><a href="#">Serviços</a></li>
            <li><a href="#">Contato</a></li>
          </ul>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  };
  </script>
  
  <style>
  .footer {
    background-color: #225378;
    color: #fff;
    padding: 2rem 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    margin-right: 2rem;
  }
  
  .footer-column:last-child {
    margin-right: 0;
  }
  
  .footer h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .footer-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 0.5rem;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  </style>
  