<template>
  <div class="development">
    <img v-lazy="require('../../assets/sobmedida.jpg')" alt="Imagem de desenvolvimento" />
    <div class="development-content">
      <h2>Desenvolvimento de softwares sob medida</h2>
      <p>A Sistnet é uma empresa de tecnologia especializada no desenvolvimento de softwares sob medida para empresas de diversos segmentos. Com anos de experiência no mercado, a Sistnet tem como objetivo oferecer soluções tecnológicas que atendam às necessidades específicas de cada cliente.</p>
      <p>Nossa equipe de desenvolvedores é formada por profissionais altamente capacitados e experientes, que trabalham com as mais recentes tecnologias e metodologias de desenvolvimento de software. Com isso, podemos garantir que nossos clientes recebam soluções personalizadas, eficientes e de alta qualidade.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InicioComponent'
  }
</script>
  
<style>
  .development {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0;
  }
  
  .development img {
    width: 100%;
    max-width: 600px;
    margin-right: 2rem;
  }
  
  .development-content {
    flex: 1;
  }
  
  .development-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .development-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  
  .btn-primary {
    display: inline-block;
    background-color: #2c3e50;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #34495e;
  }

  /* Home Component */
  @media (max-width: 768px) {
    .home-container {
      flex-direction: column;
      align-items: center;
    }

    .home-content {
      text-align: center;
    }

    .home-image {
      width: 100%;
      max-width: 300px;
    }
  }

  /* Services Component */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
  }
</style>