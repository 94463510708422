<template>
  <div class="contact-form">
    <h2>Entre em Contato</h2>
    <p>
      A Sistnet valoriza a comunicação com nossos clientes e parceiros. Estamos
      sempre disponíveis para responder às suas dúvidas, solicitações e
      esclarecer detalhes sobre nossos serviços. Entre em contato conosco e
      nossa equipe terá prazer em ajudá-lo.
    </p>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="name">Nome:</label>
        <input type="text" id="name" v-model="formData.name" required />
        <span class="error-message">{{ errors.name }}</span>
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="formData.email" required />
        <span class="error-message">{{ errors.email }}</span>
      </div>

      <div class="form-group">
        <label for="phone">Telefone de Contato:</label>
        <template v-if="$mq === 'mobile'">
          <input
            type="tel"
            id="phone"
            v-model="phone"
            @input="formatPhoneNumber"
            placeholder="(00) 00000-0000"
            required
            aria-required="true"
            :aria-invalid="errors.phone ? 'true' : 'false'"
          />
        </template>
        <template v-else>
          <input
            type="text"
            id="phone"
            v-model="phone"
            @keypress="onlyNumbers"
            @input="formatPhoneNumber"
            placeholder="(00) 00000-0000"
            required
            aria-required="true"
            :aria-invalid="errors.phone ? 'true' : 'false'"
          />
        </template>
        <span class="error-message" v-if="errors.phone">{{ errors.phone }}</span>
      </div>

      <div class="form-group">
        <label for="message">Mensagem:</label>
        <textarea id="message" v-model="formData.message" required></textarea>
        <span class="error-message">{{ errors.message }}</span>
      </div>

      <transition name="fade">
        <div class="form-message" v-if="formMessage" :class="messageType">
          {{ formMessage }}
        </div>      
      </transition>

      <button type="submit" :disabled="isSubmitting">
        <span v-if="isSubmitting">Enviando...</span>
        <span v-else>Enviar</span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContatoComponent",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      phone: "",
      errors: {},
      isSubmitting: false,
      formMessage: "", 
      messageType: ""  
    };
  },
  methods: {
    onlyNumbers(event) {
      if (!/^[0-9]*$/.test(event.key)) {
        event.preventDefault();
      }
    },
    formatPhoneNumber() {
      let cleaned = ("" + this.phone).replace(/\D/g, "");

      if (cleaned.length > 11) {
        cleaned = cleaned.slice(0, 11);
      }

      let match = cleaned.match(/^(\d{2})(\d{5})(\d{4,5})$/);
      if (match) {
        this.phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
    },
    getRecaptcha() {
      return new Promise((resolve) => {
        if (window.grecaptcha && window.grecaptcha.ready) {
          resolve(window.grecaptcha);
        } else {
          setTimeout(() => {
            resolve(this.getRecaptcha());
          }, 100);
        }
      });
    },
    async generateRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LdC6CAmAAAAALydbWfSGwlbCsSaW7ZdfxdmZp6z", {
              action: "submit",
            })
            .then((token) => {
              resolve(token);
            });
        });
      });
    },
    async submitForm() {
      this.errors = {};

      if (!this.formData.name) {
        this.errors.name = "Por favor, insira seu nome.";
      }

      if (!this.formData.email) {
        this.errors.email = "Por favor, insira seu email.";
      } else if (!this.validateEmail(this.formData.email)) {
        this.errors.email = "Por favor, insira um email válido.";
      }

      if (!this.phone) {
        this.errors.phone = "Por favor, insira seu telefone de contato.";
      }

      if (!this.formData.message) {
        this.errors.message = "Por favor, insira uma mensagem.";
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      this.isSubmitting = true;

      try {
        // Primeiro passo: verifique o reCAPTCHA
        const recaptchaResponse = await this.generateRecaptchaToken();
        const response = await axios.post(
          "https://services.sistnet.com.br/api/verify-recaptcha",
          { response: recaptchaResponse }
        );
        const data = response.data;

        if (data.success) {
          // Segundo passo: envie o e-mail se a validação do reCAPTCHA for bem-sucedida
          const requestData = {
            name: this.formData.name,
            email: this.formData.email,
            phone: this.phone,
            message: this.formData.message,
          };

          await axios.post("https://services.sistnet.com.br/api/email", requestData);
          this.isSubmitting = false;
          this.formData.name = "";
          this.formData.email = "";
          this.phone = "";
          this.formData.message = "";
          this.formMessage = "Email enviado com sucesso!";
          this.messageType = "success";

          setTimeout(() => {
            this.formMessage = "";
          }, 5000);
        } else {
          this.isSubmitting = false;
        }
      } catch (error) {
        this.isSubmitting = false;
        this.formMessage = "Ocorreu um erro ao enviar o email. Tente novamente mais tarde.";
        this.messageType = "error";

        setTimeout(() => {
          this.formMessage = "";
        }, 5000);
      }
    },
    validateEmail(email) {
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

p {
  margin-bottom: 2rem;
  text-align: justify;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  min-height: 150px;
}

button[type="submit"] {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0069d9;
  border-color: #0069d9;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
}

.form-message {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
