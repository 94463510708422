<template>
  <header class="header">
    <div class="logo">
      <img
        v-bind:style="{ opacity: imageLoaded ? 1 : 0 }"
        v-on:load="imageLoaded = true"
        src="../../assets/logo.png"
        alt="Logo da empresa"
      />
    </div>
    <nav class="menu" :class="{ 'menu-fixed': menuFixed }" ref="menu">
      <ul class="menu-list" :class="{ responsive: menuOpen }">
        <li>
          <a href="#" @click.prevent="scrollToSection('inicio')">Início</a>
        </li>
        <li>
          <a href="#" @click.prevent="scrollToSection('servicos')">Serviços</a>
        </li>
        <li>
          <a href="#" @click.prevent="scrollToSection('contato')">Contato</a>
        </li>
      </ul>
      <div class="hamburger-menu" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      menuFixed: false,
      imageLoaded: false,
      menuOpen: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const headerHeight = this.$el.offsetHeight;
      this.menuFixed = window.pageYOffset >= headerHeight && window.innerWidth <= 768;
    },
    scrollToSection(sectionRef) {
      const section = document.getElementById(sectionRef);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        this.menuOpen = false;
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>
  
<style>
  .header {
    background-color: #225378;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-list li {
    margin-right: 1rem;
  }
  
  .menu-list li:last-child {
    margin-right: 0;
  }
  
  .menu-list li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  .menu-list li a:hover {
    background-color: #34495e;
  }
  
  .menu-list li a.active {
    background-color: #34495e;
  }
  
  .menu-fixed .hamburger-menu {
    display: flex;
  }
  
  .logo {
    width:   100px;
  }

.logo img {
  height: 80px;
  max-height: 100%;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu span {
  width: 2rem;
  height: 0.25rem;
  background-color: #fff;
}

.menu-fixed .hamburger-menu {
  display: flex;
}

.menu-list.responsive {
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2c3e50;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.menu-list.responsive li {
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .menu-list {
    display: none;
  }

  .menu-list.responsive {
    display: flex;
  }
}
</style>

  